
.quokkaImg {
    opacity: 0;
    animation: fadeIn 2s;
    animation-delay: 4s;
    animation-fill-mode: both;
    height: 100vh;
    width: 100vw;
    position: static;
    z-index: -10 !important;
    overflow: hidden;
    justify-content: center;
    flex-direction: column;
    display: flex;
}
.randImg {
    height: auto;
}

.App {
    text-align: center;
    background-color: rgb(64, 61, 61);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.text h1 {
  opacity: 0;
  margin: 0 auto;
  position: static;
  margin-top: -60vh;
  animation-delay: 1s;
  animation: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
  font-size: 2rem;
  color: rgb(255, 255, 255);


}

.text h2 {
  opacity: 0;
  margin: 0 auto;
  position: static;
  animation: fadeIn;
  animation-duration: 0.5s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  color: rgb(255, 255, 255);

}


.text {
    animation: fadeOut;
    animation-duration: 2s;
    animation-delay: 4s;
    opacity: 1;
    animation-fill-mode: forwards;
}

.App-link {
    color: #61dafb;
}

/* .footerWrapper {
 opacity: 0;
  animation: fadeIn;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  /* animation-delay: 10s; 
    background-color: azure;

}

.footer {
padding-top: 10px !important;
padding-bottom: 10px !important;
height: auto;
} */

